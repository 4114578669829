import React from "react";
import { H1, Description } from "@components/Typography";
import { Link } from "gatsby";

import StyledHomeHeader from "./HomeHeader.styled";
import StyledHomeHeaderMain from "./components/HomeHeaderMain.styled";
import StyledHomeHeaderInner from "./components/HomeHeaderInner.styled";

import StyledHomeHeaderButtons from "./components/HomeHeaderButtons.styled";

import Button, { ButtonTypes } from "@components/Button";
import HeaderBackgroundIllustration from "@components/HeaderBackgroundIllustration";
import HomeHeaderBackgroundSvg from "@svg/HomeHeaderBackground";
import StaticImage from "@components/StaticImage";

interface HomeHeaderProps {}

const HomeHeader: React.FC<HomeHeaderProps> = () => {
  return (
    <StyledHomeHeader>
      <StyledHomeHeaderInner>
        <HeaderBackgroundIllustration>
          <HomeHeaderBackgroundSvg />
        </HeaderBackgroundIllustration>
        <StyledHomeHeaderMain>
          <H1>
            React <br /> Templates
          </H1>
          <Description>
            Search through a variety of react templates, react blogs,
            dashboards, landing pages, react portfolios, stores and
            many more. Check it out!
          </Description>
          <StyledHomeHeaderButtons>
            <Link to="/templates" title="Templates">
              <Button>Templates</Button>
            </Link>
            <Link to="/posts" title="Blog">
              <Button stylingType={ButtonTypes.Secondary}>
                Blog
              </Button>
            </Link>
          </StyledHomeHeaderButtons>
        </StyledHomeHeaderMain>
      </StyledHomeHeaderInner>
    </StyledHomeHeader>
  );
};

export default HomeHeader;
